import { HTTP } from '../../axios/axios'
let data

async function getAll (lang) {
  try {
    const res = await HTTP.get('/custom-search')
    data = res.data
    let arr = []
    for (const item of data) {
      if (item.lang === lang) {
        for (const subitem of item.data) {
          subitem.parentId = item._id
          subitem.parent = item.key
        }
        arr = [...arr, { title: item.key, _id: item._id, isString: true }, ...item.data]
      }
    }
    return arr
  } catch (error) {
    console.log(error)
  }
}
async function addCategory (lang, key) {
  return await HTTP.post('/custom-search', { lang, key, data: [] })
}
async function deleteCategory (item) {
  return await HTTP.delete('/custom-search/' + item._id)
}
async function changeCategory (id, key) {
  return await HTTP.patch('/custom-search/' + id, { key })
}
async function addOne (item, lang) {
  const keyData = data.find(x => x._id === item.id)
  // eslint-disable-next-line no-unused-vars
  keyData.data.push(item)
  return await HTTP.patch('/custom-search/' + item.id, { key: keyData.key, data: keyData.data })
}
async function update (item, id) {
  const keyData = data.find(x => x._id === item.parentId)
  return await HTTP.patch('/custom-search/' + keyData._id, { key: item.parent, data: keyData.data })
}
async function deleteOne (item) {
  const keyData = data.find(x => x._id === item.parentId)
  keyData.data = keyData.data.filter(x => x._id !== item._id)
  return await HTTP.patch('/custom-search/' + keyData._id, { key: item.parent, data: keyData.data })
}
async function deleteMany (arr) {
  for (const keyData of data) {
    try {
      keyData.data = keyData.data.filter(x => arr.indexOf(x._id) === -1)
      await HTTP.patch('/custom-search/' + keyData._id, { key: keyData.key, data: keyData.data })
    } catch (error) {
      console.log(error)
    }
  }
}

export default { getAll, addCategory, deleteCategory, changeCategory, deleteMany, addOne, update, deleteOne }
